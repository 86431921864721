<template>
	<div>
		<v-row>
			<v-col cols="12" sm="6" class="offset-sm-3">
				<v-card>
					<v-breadcrumbs :items="breadcrumbsItems">
						<v-icon slot="divider" class="secondary--text text--lighten-2"
							>fas fa-caret-right</v-icon
						>
					</v-breadcrumbs>
					<v-toolbar flat color="blue-grey lighten-4">
						<v-toolbar-title>{{ $t("pageTitle") }}</v-toolbar-title>
					</v-toolbar>

					<v-card-text class="pa-5">
						<form>
							<v-row>
								<v-col cols="12">
									<v-text-field
										:label="$t('passwordCurrent')"
										data-vv-name="passwordCurrent"
										:data-vv-as="$t('passwordCurrent')"
										data-vv-delay="300"
										min="6"
										v-validate="'required|min:6|max:100'"
										v-model="user.currentPassword"
										:error-messages="errors.collect('passwordCurrent')"
										prepend-icon="lock"
										:append-icon="isCPasswordVisible ? 'visibility' : 'visibility_off'"
										@click:append="isCPasswordVisible = !isCPasswordVisible"
										:type="isCPasswordVisible ? 'text' : 'password'"
									></v-text-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<v-text-field
										ref="password"
										name="password"
										:label="$t('common.password')"
										data-vv-name="password"
										:data-vv-as="$t('common.password')"
										data-vv-delay="300"
										min="6"
										v-validate="'required|min:6|max:100'"
										v-model="user.password"
										:error-messages="errors.collect('password')"
										:hint="$t('passwordHint')"
										prepend-icon="lock"
										:append-icon="isPasswordVisible ? 'visibility' : 'visibility_off'"
										@click:append="isPasswordVisible = !isPasswordVisible"
										:type="isPasswordVisible ? 'text' : 'password'"
									></v-text-field>
								</v-col>
							</v-row>

							<v-row>
								<v-col cols="12">
									<v-btn
										@click="submit"
										color="primary"
										:loading="loading"
										large
										block
										>{{ $t("passwordChange") }}</v-btn
									>
								</v-col>
							</v-row>
						</form>

						<v-alert :value="true" v-if="showSuccessMessage" type="success">{{
							$t("passwordChangeSuccess")
						}}</v-alert>

						<v-alert :value="true" v-if="showErrorMessage" type="error">{{
							errorMessage
						}}</v-alert>
					</v-card-text>
				</v-card>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import Account from "@/services/account";

export default {
	metaInfo() {
		return {
			title: this.$t("pageTitle"),
			meta: [{ name: "description", content: this.$t("pageMetaDescription") }],
		};
	},
	name: "Login",
	$_veeValidate: { validator: "new" },
	data: () => ({
		breadcrumbsItems: [
			{
				text: "Home",
				disabled: false,
				exact: true,
				to: "/",
			},
			{
				text: "Login",
				disabled: false,
				exact: true,
				to: "/Login",
			},
		],
		loading: false,
		user: {
			currentPassword: "",
			password: "",
		},
		isCPasswordVisible: false,
		isPasswordVisible: false,
		showSuccessMessage: false,
		showErrorMessage: false,
		errorMessage: "",
	}),
	methods: {
		async submit() {
			const result = await this.$validator.validate();
			this.$log.info("submit called");
			if (!result) {
				this.$log.info("not valid");
				return;
			}

			if (this.loading) return;
			this.loading = true;
			this.showErrorMessage = false;
			this.showSuccessMessage = false;

			try {
				const res = await Account.changePassword(
					this.user.currentPassword,
					this.user.password
				);
				this.$log.info("Account.changePassword: ", res);

				if (!res) {
					this.showSuccessMessage = true;
				} else {
					this.showErrorMessage = true;
					if (res.Message) {
						this.errorMessage = this.$t("changeError") + res.Message;
					} else {
						this.errorMessage = this.$t("changeErrorUnknown");
					}
				}
			} catch (Err) {
				this.$captureError(Err);
				this.showAuthError = true;
				this.authErrorMessage = Err.Message;
			} finally {
				this.loading = false;
			}
		},

		clear() {
			this.user.password_confirmation = "";
			this.user.password = "";
			this.$validator.reset();
		},
	},
	mounted() {
		this.breadcrumbsItems.push({
			text: this.$t("pageTitle"),
			disabled: true,
			exact: true,
			to: "/ChangePassword",
		});
	},
};
</script>

<i18n>
{
	"it": {
		"pageTitle": "Modifica Password",
		"pageMetaDescription": "Modifica Password",
		"passwordCurrent": "Password attuale",
		"passwordHint": "La password deve contenere un minimo di 6 caratteri",
		"passwordChange": "Modifica password",
		"passwordChangeSuccess": "Password modificata con success",
		"changeError": "Impossibile modificare la password ",
		"changeErrorUnknown": "Impossibile modificare la password (errore sconosciuto)"
	},
	"en": {
		"pageTitle": "Change Password",
		"pageMetaDescription": "Change Password",
		"passwordHint": "It should be a minimum of 6 characters",
		"passwordChangeSuccess": "Password is updated successfully"
	}
}
</i18n>
